import React, { useContext, useEffect, useState } from "react"

import CartContext from "../../contexts/cart-context"

const AddToCartButton = ({ price, MAX=10, MIN=1 }) => {
  const [quantity, setQuantity] = useState(1)
  const [totalItems, setTotalItems] = useState(0)
  const { addItem, items } = useContext(CartContext)
  const normalizedItemList = Object.values(items)

  function formatPrice(price) {
    return (price.unit_amount / 100).toFixed(2)
  }
  
  const formatedPrice = formatPrice(price)

  function addToCart() {
    if (quantity > MAX || quantity < MIN || (totalItems+quantity) > MAX) return

    const item = {
      name: price.product.name,
      price_amount: formatedPrice,
      quantity,
      price: price.id
    }
    addItem(item)
  }

  useEffect(() => {
    setTotalItems(normalizedItemList.reduce((acc, item) => {
      if (item.price === price.id) {
        return acc += item.quantity
      }

      return acc
    }, 0))
  }, [normalizedItemList, price])

  return (
    <>
      <label htmlFor="quantity">quantité</label>
      <input type="number" value={quantity} name="quantity" id="quantity" onChange={e => setQuantity(parseInt(e.target.value))} min={MIN} max={MAX} />
      <span className="validity"></span>
      <button
        className="button-link"
        onClick={addToCart}
      >
        ajouter au panier
      </button>
    </>
  )
}

export default AddToCartButton