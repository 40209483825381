import { graphql } from "gatsby"
import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Cart from "../components/cart/cart"

import "./products.css"
import AddToCartButton from "../components/cart/add-to-cart-button"

// A Stripe price is an object, containing product object as well.
const ProductsPage = ({ data }) => {
  const prices = data.prices.nodes

  function formatPrice(price) {
    return (price.unit_amount / 100).toFixed(2)
  }

  function getPriceById(priceId) {
    return prices.find(price => price.id === priceId)
  }

  // FLOWERS
  const flowersPrice = getPriceById(process.env.GATSBY_STRIPE_FLOWER_ID)
  const flowersPriceAmount = formatPrice(flowersPrice)

  // CANS
  const pack6Price = getPriceById(process.env.GATSBY_STRIPE_CAN_X6_ID)
  const pack12Price = getPriceById(process.env.GATSBY_STRIPE_CAN_X12_ID)
  const pack100Price = getPriceById(process.env.GATSBY_STRIPE_CAN_X100_ID)

  const lemonPack6Price = getPriceById(process.env.GATSBY_STRIPE_LEMON_CAN_X6_ID)
  const lemonPack12Price = getPriceById(process.env.GATSBY_STRIPE_LEMON_CAN_X12_ID)
  const lemonPack100Price = getPriceById(process.env.GATSBY_STRIPE_LEMON_CAN_X100_ID)

  const pack6PriceAmount = formatPrice(pack6Price)
  const pack12PriceAmount = formatPrice(pack12Price)
  const pack100PriceAmount = formatPrice(pack100Price)

  const lemonPack6PriceAmount = formatPrice(lemonPack6Price)
  const lemonPack12PriceAmount = formatPrice(lemonPack12Price)
  const lemonPack100PriceAmount = formatPrice(lemonPack100Price)

  // BOTTLES
  const pack6BottlesPrice = getPriceById(process.env.GATSBY_STRIPE_BOTTLE_X6_ID)
  const pack12BottlesPrice = getPriceById(process.env.GATSBY_STRIPE_BOTTLE_X12_ID)
  const pack100BottlesPrice = getPriceById(process.env.GATSBY_STRIPE_BOTTLE_X100_ID)

  const lemonPack6BottlesPrice = getPriceById(process.env.GATSBY_STRIPE_LEMON_BOTTLE_X6_ID)
  const lemonPack12BottlesPrice = getPriceById(process.env.GATSBY_STRIPE_LEMON_BOTTLE_X12_ID)
  const lemonPack100BottlesPrice = getPriceById(process.env.GATSBY_STRIPE_LEMON_BOTTLE_X100_ID)

  const pack6BottlesPriceAmount = formatPrice(pack6Price)
  const pack12BottlesPriceAmount = formatPrice(pack12Price)
  const pack100BottlesPriceAmount = formatPrice(pack100Price)

  const lemonPack6BottlesPriceAmount = formatPrice(lemonPack6Price)
  const lemonPack12BottlesPriceAmount = formatPrice(lemonPack12Price)
  const lemonPack100BottlesPriceAmount = formatPrice(lemonPack100Price)


  return (
    <Layout>
      <SEO title="ACHETER" lang="fr" />
      <main className="section-wrapper">
        <section id="buy-products" className="greed-container">
          {/* <article>
            <div className="gatsby-image-wrapper">
              <img src={canPrice.product.images[0]} alt="canette de la boisson Ibahis" />
            </div>
            <h1>{canPrice.product.name}</h1>
            <p>
              {canPrice.product.description}
            </p>
            <footer>
              <p>{canPriceAmount} € <small>- TTC la canette</small></p>
              <AddToCartButton price={canPrice} MAX={100} />
            </footer>
          </article>

          <article>
            <div className="gatsby-image-wrapper">
              <img src={lemonCanPrice.product.images[0]} alt="canette de la boisson Ibahis citron gingembre" />
            </div>
            <h1>{lemonCanPrice.product.name}</h1>
            <p>
              {lemonCanPrice.product.description}
            </p>
            <footer>
              <p>{lemonCanPriceAmount} € <small>- TTC la canette</small></p>
              <AddToCartButton price={lemonCanPrice} MAX={100} />
            </footer>
          </article> */}

          <article>
            <div className="gatsby-image-wrapper">
              <img src={pack6Price.product.images[0]} alt="pack de 6 canettes de la boisson Ibahis" />
            </div>
            <h1>{pack6Price.product.name}</h1>
            <p>
              {pack6Price.product.description}
            </p>
            <footer>
              <p>{pack6PriceAmount} € <small>- TTC les 6 canettes</small></p>
              <AddToCartButton price={pack6Price} MAX={50} />
            </footer>
          </article>

          <article>
            <div className="gatsby-image-wrapper">
              <img src={lemonPack6Price.product.images[0]} alt="pack de 6 canettes de la boisson Ibahis citron gingembre" />
            </div>
            <h1>{lemonPack6Price.product.name}</h1>
            <p>
              {lemonPack6Price.product.description}
            </p>
            <footer>
              <p>{lemonPack6PriceAmount} € <small>- TTC les 6 canettes</small></p>
              {/* <AddToCartButton price={lemonPack6Price} MAX={50} /> */}
              <p>0 en stock. Victime de son succès !</p>
            </footer>
          </article>

          <article>
            <div className="gatsby-image-wrapper">
              <img src={pack12Price.product.images[0]} alt="pack de 12 canettes de la boisson Ibahis" />
            </div>
            <h1>{pack12Price.product.name}</h1>
            <p>
              {pack12Price.product.description}
            </p>
            <footer>
              <p>{pack12PriceAmount} € <small>- TTC les 12 canettes</small></p>
              <AddToCartButton price={pack12Price} MAX={50} />
            </footer>
          </article>

          <article>
            <div className="gatsby-image-wrapper">
              <img src={lemonPack12Price.product.images[0]} alt="pack de 12 canettes de la boisson Ibahis citron gingembre" />
            </div>
            <h1>{lemonPack12Price.product.name}</h1>
            <p>
              {lemonPack12Price.product.description}
            </p>
            <footer>
              <p>{lemonPack12PriceAmount} € <small>- TTC les 12 canettes</small></p>
              {/* <AddToCartButton price={lemonPack12Price} MAX={50} /> */}
              <p>0 en stock. Victime de son succès !</p>
            </footer>
          </article>

          <article>
            <div className="gatsby-image-wrapper">
              <img src={pack100Price.product.images[0]} alt="pack de 100 bouteilles ou canettes de la boisson Ibahis" />
            </div>
            <h1>{pack100Price.product.name}</h1>
            <p>
              {pack100Price.product.description}
            </p>
            <footer>
              <p>{pack100PriceAmount} € <small>- TTC les 100 canettes</small></p>
              <AddToCartButton price={pack100Price} MAX={10} />
            </footer>
          </article>

          <article>
            <div className="gatsby-image-wrapper">
              <img src={lemonPack100Price.product.images[0]} alt="pack de 100 canettes de la boisson Ibahis citron gingembre" />
            </div>
            <h1>{lemonPack100Price.product.name}</h1>
            <p>
              {lemonPack100Price.product.description}
            </p>
            <footer>
              <p>{lemonPack100PriceAmount} € <small>- TTC les 100 canettes</small></p>
              {/* <AddToCartButton price={lemonPack100Price} MAX={10} /> */}
              <p>0 en stock. Victime de son succès !</p>
            </footer>
          </article>

          <article>
            <div className="gatsby-image-wrapper">
              <img src={flowersPrice.product.images[0]} alt="sachet de fleurs séchées d'hibiscus" />
            </div>
            <h1>{flowersPrice.product.name}</h1>
            <p>
              {flowersPrice.product.description}
            </p>
            <footer>
              <p>{flowersPriceAmount} € <small>- TTC le sachet</small></p>
              <AddToCartButton price={flowersPrice} MAX={50} />
            </footer>
          </article>

          {/* <article className="out-of-stock">
            <div className="gatsby-image-wrapper">
              <img src={bottlePrice.product.images[0]} alt="bouteille de la boisson Ibahis" />
            </div>
            <h1>{bottlePrice.product.name}</h1>
            <p>
              {bottlePrice.product.description}
            </p>
            <div className="notice-text">
              <FlagFR />
              <small>
                Toutes nos bouteilles sont certifiées <i>made in France</i>
              </small>
            </div>
            <footer>
              <p>{bottlePriceAmount} € <small>- TTC la bouteille</small></p>
              {
              //<AddToCartButton price={bottlePrice} MAX={100} />
              }
              <p>0 en stock. Victime de son succès !</p>
            </footer>
          </article> */}
        </section>
      </main>
      <Cart />
    </Layout>
  )
}

export const pageQuery = graphql`
  query ProductPrices {
    prices: allStripePrice(
      filter: {active: {eq: true}}
      sort: {fields: [unit_amount]}
    ) {
      nodes {
        active
        unit_amount
        id,
        product {
          description
          name
          images
        }
      }
    }
  }
`

export default ProductsPage
